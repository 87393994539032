import { CircularProgress as _CircularProgress, TextField as _TextField, ThemeProvider } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { Color } from "../../styles/color"
import { theme } from "../../styles/theme"
import { PrimaryButton as _PrimaryButton, Text as _Text } from "../atoms"
import CloseIcon from '@material-ui/icons/Close'
import PhoneInput from "react-phone-input-2"
import libPhoneNumber from "google-libphonenumber";
import { UserUpload } from "../../types"

interface Props extends React.HTMLAttributes<HTMLDivElement> {

    setIsVisible: (b: boolean) => void
    isUploading: boolean
    uploadError?: string
    uploadIsSuccessful: boolean
    startUpload: (u: UserUpload) => void
    newUserId: string
    tenantId: string
    externalIdFieldName?: string
}

const CreateUser: React.FC<Props> = ({setIsVisible, isUploading, uploadIsSuccessful, uploadError, startUpload, newUserId, tenantId, externalIdFieldName, ...props}) => {

    const [ firstName, setFirstName ] = React.useState("")
    const [ lastName, setLastName ] = React.useState("")
    const [ phoneNumber, setPhoneNumber ] = React.useState("")
    const [ phoneNumberIsValid, setPhoneNumberIsValid ] = React.useState(false)
    const [ phoneNumberIsFocused, setPhoneNumberIsFocused ] = React.useState(false)
    const [ firstNameIsFocused, setFirstNameIsFocused ] = React.useState(false)
    const [ firstNameHasBeenFocused, setFirstNameHasBeenFocused ] = React.useState(false)
    const [ lastNameHasBeenFocused, setLastNameHasBeenFocused ] = React.useState(false)
    const [ lastNameIsFocused, setLastNameIsFocused ] = React.useState(false)
    const [ externalId, setExternalId ] = React.useState<string | undefined>(undefined)

    const buttonIsEnabled = (firstName.length && lastName.length && phoneNumberIsValid) ? true : false

    return <Overlay onClick={() => setIsVisible(false)} {...props}>
        <ThemeProvider theme={theme}>
            <Wrapper onClick={e => e.stopPropagation()} height={externalIdFieldName ? "550px" : "450px"}>
                { uploadIsSuccessful ? <>
                    <SuccessText>Created successfully</SuccessText>
                    <PrimaryButton isEnabled={true} onClick={() => setIsVisible(false)}>Done</PrimaryButton>
                </>
                
                :

                <>
                    <CloseButton onClick={() => setIsVisible(false)}/>
                    <Title size="large">Add user</Title>
                    <TextField label="First name" margin="normal" required value={firstName} onChange={e => setFirstName(e.target.value)} onFocus={() => {setFirstNameIsFocused(true); setFirstNameHasBeenFocused(true);}} onBlur={() => setFirstNameIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(firstName.length === 0) && !firstNameIsFocused && firstNameHasBeenFocused}>Please enter a first name</ErrorLabel>
                    <TextField label="Last name" margin="normal" required value={lastName} onChange={e => setLastName(e.target.value)} onFocus={() => {setLastNameIsFocused(true); setLastNameHasBeenFocused(true); }} onBlur={() => setLastNameIsFocused(false)}/>
                    <ErrorLabel color={Color.red} size="small" isVisible={(lastName.length === 0) && !lastNameIsFocused && lastNameHasBeenFocused}>Please enter a last name</ErrorLabel>
                    {externalIdFieldName && <TextField label={externalIdFieldName} margin="normal" required value={externalId} onChange={e => setExternalId(e.target.value)}/>}
                    <PhoneInputWrapper>
                        <PhoneLabel color={Color.grey2}>Mobile number</PhoneLabel>
                        <PhoneInput placeholder=""
                                    country={'gb'}
                                    value={phoneNumber}
                                    enableSearch={true}
                                    onChange={s => {setPhoneNumber(s); setPhoneNumberIsValid(isValid(s))}}
                                    onFocus={() => setPhoneNumberIsFocused(true)}
                                    onBlur={() => setPhoneNumberIsFocused(false)}
                                    preferredCountries={['gb','us', 'ca', 'au', 'nz', 'sp']}/>
                        <ErrorLabel color={Color.red} size="small" isVisible={(phoneNumber.length > 0) && !phoneNumberIsFocused && !phoneNumberIsValid}>The phone number you entered is not valid</ErrorLabel>
                    </PhoneInputWrapper>
                    <PrimaryButton isEnabled={buttonIsEnabled} onClick={() => buttonIsEnabled && !isUploading && startUpload({firstName, lastName, phoneNumber: "+" + phoneNumber, tenantId, userId: newUserId, externalId})}>
                        { isUploading ? <CircularProgress style={{color: Color.black}} size="30px"/> : "Create"}
                    </PrimaryButton>
                    <UrlErrorLabel color={Color.red} size="small" isVisible={uploadError?.length ? true : false}>{uploadError}</UrlErrorLabel>
                </>}
            </Wrapper>
        </ThemeProvider>
    </Overlay>
}

export default CreateUser

const isValid = (phoneNumberString: string) => {

    const withPlus = "+" + phoneNumberString
    let isValid = false

    try {
        const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance()
        const phoneNumber = phoneUtil.parse(withPlus, "GB")
        isValid = phoneUtil.isValidNumber(phoneNumber)
    }
    catch (e) {
        //console.log(e)
    }

    return isValid
}

const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: ${Color.translucentBlack};
    z-index: 20;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow-x: hidden;
    overflow-y: auto;
`

const Wrapper = styled.div<{height: string}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: ${Color.white};
    width: 300px;
    height: ${props => props.height};
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
    padding: 40px;
`

const Title = styled(_Text)`
    margin: 0 auto 20px auto;
    text-align: center;
    align-self: center;
`

const TextField = styled(_TextField)`
    /* width: 200px; */
`

const CloseButton = styled(CloseIcon)`
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
        cursor: pointer;
    }
`

const PhoneInputWrapper = styled.div`
    margin-top: 30px;
`

const PhoneLabel = styled(_Text)`
    margin-bottom: 8px;
`

const ErrorLabel = styled(_Text)<{isVisible: boolean}>`
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
    margin-top: 4px;
`

const UrlErrorLabel = styled(ErrorLabel)`
    text-align: center;
`

const PrimaryButton = styled(_PrimaryButton)`
    margin-top: 30px;
    align-self: center;
`

const CircularProgress = styled(_CircularProgress)`
    margin-top: 5px;
`



const SuccessText = styled(_Text)`
    margin-top: 200px;
    text-align: center;
`