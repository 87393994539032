import { Authenticated } from 'ra-core'
import React, { FC, useCallback } from 'react'
import styled from 'styled-components'
import { Menu as _Menu } from '../../components/layouts'
import config from '../../config'
import { useWebGet } from '../../hooks/useWebGet'
import { getAdminId, getFirstName, getLastName, getSatisfaction, getTenants, logout, persistSatisfaction, usesWettonLogin } from '../../providers/auth.service'
import { DateTime } from 'luxon'
// import { getThemeConfig } from '../../styles/theme'
import { BarChartCard as _BarChartCard,
         ProgressCircleCard as _ProgressCircleCard,
         TableCard as _TableCard,
         SiteHeader as _SiteHeader,
         ColumnListCard as _ColumnListCard,
         TrainingCard as _TrainingCard,
         DueOverdueCard as _DueOverdueCard,
         ChangePassword} from '../../components/organisms'
import { FailureReport, InspectionSummaryByMonth, InspectionSummary, OverdueSummary, ScheduleSummary, Tenant, toUrlDate, TrainingPage, User } from '../../types'
import { Color } from '../../styles/color'
import { Opacity, Weight } from '../../components/atoms'
import { UserListItem as _UserListItem, QuickLinksCard as _QuickLinksCard, AdminHeader, FeedbackCard as _FeedbackCard } from '../../components/molecules'
import { menuOptionsForConfig } from '../../components/layouts/menu'
import { useHistory, useLocation } from 'react-router-dom'
import { useWebPut } from '../../hooks/useWebPut'
import queryString from 'query-string'
import ZendeskWidget from './Zendesk'
// import useWindowSize from '../../hooks/useWindowSize'


const Dashboard: FC = () => {

    const history = useHistory()
    const location = useLocation()
    const tenants = getTenants()

    if (!tenants?.length) {
        history.push("/login")
    }

    const { tenantId, siteId } = queryString.parse(location.search)

    const tenant = tenants?.[0]         // TODO: Proper check for undefined
    const siteConfig = tenant?.siteConfig   // TODO: Type narrowing to avoid needing this check for undefined
    const subTenants = tenant?.subTenants?.length ? tenant.subTenants : undefined
    const tenantsToUse = (siteConfig?.managesSubTenants && subTenants) ? [tenant, ...subTenants] : [tenant]

    const [ changePasswordIsVisible, setChangePasswordIsVisible ] = React.useState(false)
    const [ startDate, setStartDate ] = React.useState(DateTime.now().minus({days: 30}))
    const [ endDate, setEndDate ] = React.useState(DateTime.now())
    // const [ selectedTenant, setSelectedTenant ] = React.useState(tenantsToUse?.[0])
    // const [ selectedSite, setSelectedSite ] = React.useState<Site | undefined>((tenant?.sites?.length === 1) ? tenant.sites[0] : undefined)
    const [ satisfaction, setSatisfaction ] = React.useState<Record<string, number>>(getSatisfaction() ?? {})

    const selectedTenant = tenantId ? (tenantsToUse.filter(t => t?.tenantId === tenantId)[0] ?? tenantsToUse[0])
                                    : tenantsToUse[0]
    const selectedSite = selectedTenant?.sites?.filter(s => (s.id === siteId))[ 0 ] ?? undefined

    const tenantQuickLinks = selectedTenant?.siteConfig?.dashboardConfig.quickLinks

    const reportId = selectedTenant?.reportExpectations?.length ? selectedTenant.reportExpectations[0] : undefined
    const siteQuery = selectedSite ? `siteId=${selectedSite?.id}` : ""
    const siteQueryAmpersand = "&" + siteQuery
    const siteQueryQuestionMark = "?" + siteQuery
    const {
        isLoading: qualityIsLoading,
        errorCode: qualityErrorCode,
        data: qualityData } = useWebGet<InspectionSummary>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/inspections/quality?from=${toUrlDate(startDate)}&to=${toUrlDate(endDate.plus({days: 1}))}${siteQueryAmpersand}`,
                                                                  !!selectedTenant && (siteConfig?.dashboardConfig.hasQuality === true))

    const {
        isLoading: healthAndSafetyIsLoading,
        errorCode: healthAndSafetyErrorCode,
        data: healthAndSafetyData } = useWebGet<InspectionSummaryByMonth>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/inspections/health-and-safety?from=${toUrlDate(DateTime.now().minus({years: 1}))}&to=${toUrlDate(DateTime.now())}${siteQueryAmpersand}`,
                                                                                  !!selectedTenant && (siteConfig?.dashboardConfig.hasHealthAndSafety === true))

    const {
        isLoading: scheduleIsLoading,
        errorCode: scheduleErrorCode,
        data: scheduleData
    } = useWebGet<ScheduleSummary[]>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/schedules${siteQueryQuestionMark}`,
                                     !!selectedTenant && (siteConfig?.dashboardConfig.hasSchedule === true))
    const {
        isLoading: usersAreLoading,
        errorCode: usersErrorCode,
        data: usersData,
    } = useWebGet<User[]>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/report/expectations/${reportId}/live?pagesize=100${siteQueryAmpersand}`,
                          !!selectedTenant && (siteConfig?.dashboardConfig.hasCurrentlyOnSite === true)) // TODO: proper pagination
    const {
        isLoading: trainingIsLoading,
        errorCode: trainingErrorCode,
        data: trainingData,
    } = useWebGet<TrainingPage>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/report/expectations/training?pagesize=100`,
                                !!selectedTenant && (siteConfig?.dashboardConfig.hasTraining === true)) // TODO: proper pagination

    const {
        isLoading: overdueIsLoading,
        errorCode: overdueErrorCode,
        data: overdueData,
    } = useWebGet<OverdueSummary>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/schedules/overdue?from=${toUrlDate(startDate)}&to=${toUrlDate(endDate.plus({days: 1}))}${siteQueryAmpersand}`,
        !!selectedTenant && (siteConfig?.dashboardConfig.hasOverdue === true))


    const {
        isLoading: satisfactionIsUploading,
        // isSuccess: satisfactionUploadIsSuccessful,
        errorCode: satisfactionUploadErrorCode,
        start: startSatisfactionUpload,
        reset: resetSatisfactionUpload,
    } = useWebPut((isSuccess, context) => {

        if (isSuccess) {
            persistSatisfaction(context)
        }
    })

    const updateSatisfaction = useCallback((s?: number) => {
        if (selectedSite && (s !== undefined)) {
            const hasChanged = (s !== satisfaction[selectedSite.id])

            if (hasChanged) {
                setSatisfaction({...satisfaction, [selectedSite.id]: s})
                resetSatisfactionUpload()
                startSatisfactionUpload(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/admin/${getAdminId()}/sites/${selectedSite.id}/satisfaction`,
                                        { satisfaction: s },
                                        {
                                            tenantId: selectedTenant?.tenantId,
                                            siteId: selectedSite?.id,
                                            satisfaction: s,
                                        })
            }
        }
    }, [ selectedSite, satisfaction, selectedTenant, setSatisfaction, resetSatisfactionUpload, startSatisfactionUpload ])


    const overdueTotal = overdueData ? (overdueData.onTime + overdueData.overdue) : 0
    const needCards = siteConfig && (siteConfig.dashboardConfig.hasSurvey || siteConfig.dashboardConfig.hasHealthAndSafety
        || siteConfig.dashboardConfig.hasQuality || siteConfig.dashboardConfig.hasTraining || siteConfig.dashboardConfig.hasSchedule
        || siteConfig.dashboardConfig.hasCurrentlyOnSite || siteConfig.dashboardConfig.hasOverdue
        || (tenantQuickLinks && tenantQuickLinks.length))

    return <Authenticated>
        { siteConfig && tenantsToUse && selectedTenant ? <Wrapper>
            { changePasswordIsVisible && <ChangePassword setIsVisible={setChangePasswordIsVisible}/> }
            <Menu options={menuOptionsForConfig(siteConfig)} selectedOption="Dashboard" />
            <Content>
                <AdminHeader logoImageName={tenant?.logoImageName} firstName={getFirstName() ?? ""} lastName={getLastName() ?? ""} logout={() => { const usesWetton = usesWettonLogin(); logout(); history.push(usesWetton ? "/wetton" : "/login")}} setChangePasswordIsVisible={setChangePasswordIsVisible}/>
                <SiteHeader tenants={tenantsToUse as Tenant[]}
                            selectedTenant={selectedTenant}
                            changeTenantText={siteConfig.changeSubTenantText ?? "Change dashboard"}
                            setSelectedTenant={t => history.push(location.pathname + "?tenantId=" + t.tenantId)}
                            selectedSite={selectedSite}
                            setSelectedSite={s => history.push(location.pathname + "?tenantId=" + selectedTenant.tenantId + (s ? "&siteId=" + s.id : ""))}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}/>
                { needCards ? <CardContainer>

                    {siteConfig.dashboardConfig.hasSurvey && selectedSite && <FeedbackCard isLoading={satisfactionIsUploading}
                                                                                           satisfaction={satisfaction[selectedSite.id]}
                                                                                           error={satisfactionUploadErrorCode ? "Something went wrong and your response could not be uploaded, please try again" : undefined}
                                                                                           setSatisfaction={updateSatisfaction}/>}

                    {siteConfig.dashboardConfig.hasHealthAndSafety && <BarChartCard isLoading={healthAndSafetyIsLoading}
                                                                                    error={healthAndSafetyErrorCode ? "Something went wrong, please refresh to try again" : undefined}
                                                                                    title="Wetton H&amp;S compliance"
                                                                                    subTitle="Failures"
                                                                                    hasLink={siteConfig.dashboardConfig.hasCardLinks}
                                                                                    yAxisIsPercentage={true}
                                                                                    hasList={true}
                                                                                    listItems={healthAndSafetyData ? getFailureList(healthAndSafetyData.failures) : []}
                                                                                    chartItems={healthAndSafetyData ? healthAndSafetyData.report.map(o => {return {
                                                                                        label: DateTime.fromObject({year: o.year, month: o.month}).toFormat("MMM"),
                                                                                        backgroundValue: 0,
                                                                                        foregroundValue: o.successPercentage,
                                                                                    }}) : []}/>}
                    {siteConfig.dashboardConfig.hasQuality && <ProgressCircleCard isLoading={qualityIsLoading}
                                                                                  error={qualityErrorCode ? "Something went wrong, please refresh to try again" : undefined}
                                                                                  title="Quality summary"
                                                                                  subTitle="Common failures"
                                                                                  hasLink={siteConfig.dashboardConfig.hasCardLinks}
                                                                                  hasList={true}
                                                                                  listItems={getFailureList(qualityData ? qualityData.failures : {})}
                                                                                  //progress={!(qualityData?.itemCount) ? 0 : qualityData.successCount / qualityData.itemCount}
                                                                                  indicatorCount={qualityData?.successCount ?? 0}
                                                                                  totalCount={qualityData?.itemCount ?? 0}
                                                                                  showsPercentage={true}
                                                                                  progressColor={Color.blue}
                                                                                  trackColor={Color.green4}
                                                                                  progressFontSize="36px"/>}
                    {siteConfig.dashboardConfig.hasTraining && <TrainingCard isLoading={trainingIsLoading}
                                                                             error={trainingErrorCode ? "Something went wrong, please refresh to try again" : undefined}
                                                                             fractionNotFullyTrained={trainingData ? trainingData.users.filter(u => u.report.some(r => r.isDue)).length / trainingData.users.length : 0}
                                                                             hasLink={siteConfig.dashboardConfig.hasCardLinks}
                                                                             users={trainingData ? trainingData.users.map(u => {
                                                                                 return {
                                                                                     name: u.firstName + " " + u.lastName,
                                                                                     trainingFraction: (u.report.filter(r => !r.isDue).length / u.report.length),
                                                                                 }}) : []}/>}
                    {siteConfig.dashboardConfig.hasSchedule && <TableCard isLoading={scheduleIsLoading}
                                                                          error={scheduleErrorCode ? "Something went wrong, please refresh to try again" : undefined}
                                                                          title="Periodic schedule"
                                                                          hasLink={siteConfig.dashboardConfig.hasCardLinks}
                                                                          headers={["Task", "Frequency", "Last done", "Next due"]}
                                                                          rows={scheduleData ? getScheduleTable(scheduleData): []}/>}
                    {siteConfig.dashboardConfig.hasCurrentlyOnSite && <ColumnListCard isLoading={usersAreLoading}
                                                                                      error={usersErrorCode ? "Something went wrong, please refresh to try again" : undefined}
                                                                                      title="Staff on site"
                                                                                      number={usersData?.length ?? 0}>
                                                                            {usersData && usersData.map(u => <UserListItem {...u}/>)}
                                                                        </ColumnListCard>}

                    {siteConfig.dashboardConfig.hasOverdue && <DueOverdueCard isLoading={overdueIsLoading}
                                                                              error={overdueErrorCode ? "Something went wrong, please refresh to try again" : undefined}
                                                                              title="Cleaning schedule"
                                                                              hasLink={siteConfig.dashboardConfig.hasCardLinks}
                                                                              onTimeFraction={ overdueData && overdueTotal ? (overdueData.onTime / overdueTotal) : 0}
                                                                              overdueFraction={ overdueData && overdueTotal ? (overdueData.overdue / overdueTotal) : 0}/>}

                    { (tenantQuickLinks && tenantQuickLinks.length) && <QuickLinksCard quickLinks={tenantQuickLinks}/> }
                    <ZendeskWidget />
                </CardContainer>: <div></div>}
            </Content>
        </Wrapper> : <div></div>}
    </Authenticated>
}

export default Dashboard

const getFailureList = (data: FailureReport) => {

    let listItems = new Array<{label: string, count: number}>()

    for (const key in data) {
        listItems.push(data[key])
    }

    return listItems.sort((a, b) => b.count - a.count)
                    .filter((_, i) => i < 3)
                    .map(li => li.label)
}

const getScheduleTable = (data: ScheduleSummary[]) => {

    const now = DateTime.utc()

    const withDates = data.map(s => {
        return {
            ...s,
            previousOccurrenceDate: s.previousOccurrenceDate ? DateTime.fromISO(s.previousOccurrenceDate) : undefined,
            nextOccurrenceDate: s.nextOccurrenceDate ? DateTime.fromISO(s.nextOccurrenceDate) : undefined,
        }
    })

    const sorted = withDates.sort((a, b) => a.nextOccurrenceDate && b.nextOccurrenceDate ? a.nextOccurrenceDate.diff(b.nextOccurrenceDate).valueOf()
                                                                  : a.nextOccurrenceDate ? -1
                                                                                         : +1)

    return sorted.map(s => {

        const isSoon = s.nextOccurrenceDate ? (now.diff(s.nextOccurrenceDate, "weeks").weeks <= 1)
                                            : false

        return [
            {
                text: s.name,
                color: Color.black,
                weight: "normal" as Weight,
                opacity: "full" as Opacity
            },
            {
                text: s.frequency,
                color: Color.grey4,
                weight: "normal" as Weight,
                opacity: "full" as Opacity
            },
            {
                text: s.previousOccurrenceDate ? s.previousOccurrenceDate.toFormat("d MMM yyyy") : "-",
                color: Color.black,
                weight: "normal" as Weight,
                opacity: "full" as Opacity,
            },
            {
                text: s.nextOccurrenceDate ? s.nextOccurrenceDate.toFormat("d MMM yyyy") : "-",
                color: isSoon ? Color.blue : Color.grey4,
                weight: isSoon ? "bold" : "normal" as Weight,
                opacity: "full" as Opacity,
            }
        ]
    })
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${Color.lightGrey};
`

const Content = styled.div`
    flex-shrink: 1;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
`

const Menu = styled(_Menu)`
    flex-shrink: 0;
`

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    margin: -20px;
    padding: 35px;
    box-sizing: border-box;
`

const BarChartCard = styled(_BarChartCard)`
    width: 400px;
    object-fit: scale-down;
    margin: 20px;
    flex-grow: 1;
    flex-basis: 200px;
    max-height: 400px;
`

const ProgressCircleCard = styled(_ProgressCircleCard)`
    margin: 20px;
    flex-grow: 1;
    flex-basis: 200px;
`

const TableCard = styled(_TableCard)`
    margin: 20px;
    max-width: 650px;
    max-height: 400px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
`

const FeedbackCard = styled(_FeedbackCard)`
    margin: 20px;
    flex-basis: 300px;
`

const SiteHeader = styled(_SiteHeader)`
    min-height: 100px;
    box-sizing: border-box;
`

const ColumnListCard = styled(_ColumnListCard)`
    margin: 20px;
    min-width: 200px;
    max-height: 400px;
    flex-grow: 1;
`

const TrainingCard = styled(_TrainingCard)`
    margin: 20px;
    max-height: 400px;
    max-width: 350px;
    flex-grow: 1;
    flex-basis: 200px;
`

const UserListItem = styled(_UserListItem)`
    margin-bottom: 20px
`

const DueOverdueCard = styled(_DueOverdueCard)`
    margin: 20px;
    min-width: 230px;
    max-width: 280px;
    max-height: 250px;
    flex-basis: 230px;
    flex-grow: 1;
`

const QuickLinksCard = styled(_QuickLinksCard)`
    margin: 20px;
    min-width: 150px;
    max-width: 400px;
    max-height: 400px;
    flex-grow: 1;
`
