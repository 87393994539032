import { useEffect } from 'react';

function ZendeskWidget() {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=e7bf7adb-d23f-4f5f-80ea-52f97927b4f0';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup the script when the component is unmounted
    };
  }, []);

  return null; // This component doesn't render anything in the DOM
}

export default ZendeskWidget;
